var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab-general md:ml-4 md:mt-0 mt-4 ml-0"},[_c('vx-card',[_c('div',{attrs:{"id":"user-edit-tab-info"}},[_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full mb-base"},[_c('div',{staticClass:"flex items-end md:mt-0 mt-base mb-3"},[_c('feather-icon',{staticClass:"mr-2",attrs:{"icon":"UsersIcon","svgClasses":"w-5 h-5"}}),_c('span',{staticClass:"leading-none font-medium"},[_vm._v("Users")])],1),_c('vs-alert',[_vm._v("Below are the users who have access to your broker account")]),_c('vs-table',{ref:"table",staticClass:"mt-6",attrs:{"sst":true,"data":_vm.users},scopedSlots:_vm._u([{key:"default",fn:function({ data }){return [_c('tbody',_vm._l((data),function(user,index){return _c('vs-tr',{key:index,attrs:{"data":user}},[_c('vs-td',[_vm._v(" "+_vm._s(user.email)+" ")]),_c('vs-td',[_vm._v(" "+_vm._s(_vm.formatDateAndTime( user.last_login ))+" ")]),_c('vs-td',[_vm._v(" "+_vm._s(_vm.formatDateAndTime( user.date_joined ))+" ")]),_c('vs-td',[_c('div',{staticClass:"flex justify-end row-hidden-elements"},[(
                                                    user.email !==
                                                    _vm.$store.getters.user
                                                        .email
                                                )?_c('vx-tooltip',{attrs:{"text":"Revoke Access","title":"Remove","color":"primary"}},[_c('vs-button',{attrs:{"type":"flat","icon-pack":"feather","icon":"icon-delete"},on:{"click":function($event){return _vm.removeAccessConfirm(
                                                            user
                                                        )}}})],1):_vm._e()],1)])],1)}),1)]}}])},[_c('div',{staticClass:"flex items-center flex-grow justify-end mb-6",attrs:{"slot":"header"},slot:"header"},[_c('div',[_c('vx-input-group',{},[_c('vs-input',{attrs:{"placeholder":"Email address","color":_vm.errors.email
                                                ? 'secondary'
                                                : 'primary'},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('template',{slot:"append"},[_c('div',{staticClass:"append-text btn-addon"},[_c('vs-button',{staticClass:"vs-con-loading__container",attrs:{"id":"button-with-loading","color":_vm.errors.email
                                                        ? 'secondary'
                                                        : 'primary',"disabled":!_vm.validateForm},on:{"click":_vm.invite}},[_vm._v("Send Access Invitation")])],1)])],2),(_vm.errors.email !== null)?_c('p',{staticClass:"vs-input--label text-secondary"},[_vm._v(" "+_vm._s(_vm.errors.email)+" ")]):_vm._e()],1)]),_c('template',{slot:"thead"},[_c('vs-th',[_vm._v("Email")]),_c('vs-th',[_vm._v("Last Login")]),_c('vs-th',[_vm._v("Created")]),_c('vs-th')],1)],2)],1)]),_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full mb-base"},[_c('div',{staticClass:"flex items-end md:mt-0 mt-base mb-3"},[_c('feather-icon',{staticClass:"mr-2",attrs:{"icon":"GitPullRequestIcon","svgClasses":"w-5 h-5"}}),_c('span',{staticClass:"leading-none font-medium"},[_vm._v("Access Requests Sent")])],1),_c('vs-alert',[_vm._v("Below are pending access invitations")]),_c('vs-table',{ref:"table",staticClass:"mt-6",attrs:{"sst":true,"data":_vm.accessRequests},scopedSlots:_vm._u([{key:"default",fn:function({ data }){return [_c('tbody',_vm._l((data),function(request,index){return _c('vs-tr',{key:index,attrs:{"data":_vm.user}},[_c('vs-td',[_vm._v(" "+_vm._s(request.email)+" ")]),_c('vs-td',[_c('div',{staticClass:"flex justify-end row-hidden-elements"},[_c('vx-tooltip',{attrs:{"text":"Cancel Access Request","title":"Remove","color":"primary"}},[_c('vs-button',{attrs:{"type":"flat","icon-pack":"feather","icon":"icon-delete"},on:{"click":function($event){return _vm.removeAccessRequestConfirm(
                                                            request
                                                        )}}})],1)],1)])],1)}),1)]}}])},[_c('template',{slot:"thead"},[_c('vs-th',[_vm._v("Email")]),_c('vs-th')],1)],2)],1)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }