<template>
    <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
        <vx-card>
            <div id="user-edit-tab-info">
                <div class="vx-row">
                    <div class="vx-col w-full md:w-1/2 mb-base">
                        <!-- Col Header -->
                        <div class="flex items-end md:mt-0 mt-base">
                            <feather-icon
                                icon="UserIcon"
                                class="mr-2"
                                svgClasses="w-5 h-5"
                            />
                            <span class="leading-none font-medium"
                                >Username</span
                            >
                        </div>

                        <div class="mb-3">
                            <vs-input
                                class="w-full mt-4"
                                label="Username"
                                v-model="form.email"
                                name="username"
                                :danger="errors.email !== null"
                                :danger-text="errors.email"
                            />
                        </div>
                        <div class="vx-row">
                            <div class="flex flex-wrap items-center">
                                <vs-button
                                    class="bg-success ml-4 mt-2"
                                    icon-pack="feather"
                                    icon="icon-save"
                                    :disabled="!validateForm"
                                    v-on:click="save"
                                    >Change</vs-button
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </vx-card>
    </div>
</template>

<script>
import { http } from '@/services'
import { textFormat } from '@/mixins/textFormat'

export default {
    name: 'UsernameTab',
    mixins: [textFormat],
    mounted() {
        this.form.email = this.$store.getters.user.email
    },
    data() {
        return {
            users: [],
            accessRequests: [],
            selectedUser: null,
            selectedAccessRequest: null,
            form: {
                email: null,
            },
            errors: {
                email: null,
            },
        }
    },
    computed: {
        validateForm() {
            return !!this.form.email
        },
    },
    methods: {
        handleErrors(errors) {
            if (errors.email) this.errors.email = errors.email[0]
        },
        clearErrors() {
            this.errors.email = null
        },
        clearForm() {
            this.form.email = null
        },
        save() {
            this.clearErrors()
            http.put('username_change', this.form)
                .then((response) => {
                    this.clearForm()
                    this.$store.dispatch('userFetch')
                    this.$vs.notify({
                        title: 'Success',
                        text: 'Username Changed',
                        color: 'success',
                        icon: 'icon-lock',
                        iconPack: 'feather',
                        position: 'top-center',
                    })
                })
                .catch((error) => {
                    this.handleErrors(error.response.data)
                })
        },
    },
}
</script>

<style>
.row-hidden-elements {
    visibility: hidden;
}
.vs-table tr:hover .row-hidden-elements {
    visibility: visible;
}
</style>
