<template>
    <div id="page-user-edit">
        <div
            slot="no-body"
            class="tabs-container px-6 pt-6 vs-con-loading__container"
        >
            <vs-tabs
                :position="isSmallerScreen ? 'top' : 'left'"
                class="tabs-shadow-none"
                id="profile-tabs"
                :key="isSmallerScreen"
            >
                <vs-tab
                    :label="!isSmallerScreen ? 'General' : ''"
                    icon-pack="feather"
                    icon="icon-home"
                >
                    <GeneralTab :isSmallerScreen="isSmallerScreen" />
                </vs-tab>
                <vs-tab
                    :label="!isSmallerScreen ? 'User Access' : ''"
                    icon-pack="feather"
                    icon="icon-user-plus"
                >
                    <UsersTab :isSmallerScreen="isSmallerScreen" />
                </vs-tab>
                <vs-tab
                    :label="!isSmallerScreen ? 'Username' : ''"
                    icon-pack="feather"
                    icon="icon-user"
                >
                    <UsernameTab :isSmallerScreen="isSmallerScreen" />
                </vs-tab>
                <vs-tab
                    :label="!isSmallerScreen ? 'Password' : ''"
                    icon-pack="feather"
                    icon="icon-lock"
                >
                    <PasswordTab :isSmallerScreen="isSmallerScreen" />
                </vs-tab>
                  <vs-tab
                    :label="!isSmallerScreen ? 'Notifications' : ''"
                    icon-pack="feather"
                    icon="icon-bell"
                >
                    <NotificationsTab :isSmallerScreen="isSmallerScreen" />
                </vs-tab>
            </vs-tabs>
        </div>
        <!-- Save & Reset Button -->
        <div class="vx-row">
            <div class="vx-col w-full">
                <div class="mt-8 flex flex-wrap items-center justify-end"></div>
            </div>
        </div>
    </div>
</template>

<script>
import GeneralTab from './Tabs/general'
import UsersTab from './Tabs/users'
import PasswordTab from '@/views/main/settings/Tabs/password'
import UsernameTab from '@/views/main/settings/Tabs/username'
import NotificationsTab from '@/views/main/settings/Tabs/notifications'

export default {
    name: 'Settings',
    components: {
        UsernameTab,
        PasswordTab,
        GeneralTab,
        UsersTab,
        NotificationsTab
    },
    computed: {
        isSmallerScreen() {
            return this.$store.state.windowWidth < 768
        },
    },
}
</script>

<style lang="scss">
#avatar-col {
    width: 10rem;
}
.image {
    height: 300px;
}
#page-user-view {
    table {
        td {
            vertical-align: top;
            min-width: 140px;
            padding-bottom: 0.8rem;
            word-break: break-all;
        }

        &:not(.permissions-table) {
            td {
                @media screen and (max-width: 370px) {
                    display: block;
                }
            }
        }
    }
}

@media screen and (min-width: 1201px) and (max-width: 1211px),
    only screen and (min-width: 636px) and (max-width: 991px) {
    #account-info-col-1 {
        width: calc(100% - 12rem) !important;
    }
}
</style>
