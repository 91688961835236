<template>
    <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
        <vx-card>
            <div id="user-edit-tab-info">
                <div class="vx-row">
                    <div class="vx-col w-full mb-base">
                        <!-- Col Header -->
                        <div class="flex items-end md:mt-0 mt-base mb-3">
                            <feather-icon
                                icon="UsersIcon"
                                class="mr-2"
                                svgClasses="w-5 h-5"
                            />
                            <span class="leading-none font-medium">Users</span>
                        </div>
                        <vs-alert
                            >Below are the users who have access to your broker
                            account</vs-alert
                        >
                        <vs-table
                            ref="table"
                            :sst="true"
                            :data="users"
                            class="mt-6"
                        >
                            <div
                                slot="header"
                                class="flex items-center flex-grow justify-end mb-6"
                            >
                                <div>
                                    <vx-input-group class="">
                                        <vs-input
                                            placeholder="Email address"
                                            v-model="form.email"
                                            :color="
                                                errors.email
                                                    ? 'secondary'
                                                    : 'primary'
                                            "
                                        />

                                        <template slot="append">
                                            <div class="append-text btn-addon">
                                                <vs-button
                                                    id="button-with-loading"
                                                    class="vs-con-loading__container"
                                                    @click="invite"
                                                    :color="
                                                        errors.email
                                                            ? 'secondary'
                                                            : 'primary'
                                                    "
                                                    :disabled="!validateForm"
                                                    >Send Access
                                                    Invitation</vs-button
                                                >
                                            </div>
                                        </template>
                                    </vx-input-group>
                                    <p
                                        class="vs-input--label text-secondary"
                                        v-if="errors.email !== null"
                                    >
                                        {{ errors.email }}
                                    </p>
                                </div>
                            </div>

                            <template slot="thead">
                                <vs-th>Email</vs-th>
                                <vs-th>Last Login</vs-th>
                                <vs-th>Created</vs-th>
                                <vs-th></vs-th>
                            </template>

                            <template slot-scope="{ data }">
                                <tbody>
                                    <vs-tr
                                        :data="user"
                                        :key="index"
                                        v-for="(user, index) in data"
                                    >
                                        <vs-td>
                                            {{ user.email }}
                                        </vs-td>

                                        <vs-td>
                                            {{
                                                formatDateAndTime(
                                                    user.last_login
                                                )
                                            }}
                                        </vs-td>

                                        <vs-td>
                                            {{
                                                formatDateAndTime(
                                                    user.date_joined
                                                )
                                            }}
                                        </vs-td>

                                        <vs-td>
                                            <div
                                                class="flex justify-end row-hidden-elements"
                                            >
                                                <vx-tooltip
                                                    v-if="
                                                        user.email !==
                                                        $store.getters.user
                                                            .email
                                                    "
                                                    text="Revoke Access"
                                                    title="Remove"
                                                    color="primary"
                                                >
                                                    <vs-button
                                                        type="flat"
                                                        icon-pack="feather"
                                                        icon="icon-delete"
                                                        @click="
                                                            removeAccessConfirm(
                                                                user
                                                            )
                                                        "
                                                    ></vs-button>
                                                </vx-tooltip>
                                            </div>
                                        </vs-td>
                                    </vs-tr>
                                </tbody>
                            </template>
                        </vs-table>
                    </div>
                </div>
                <div class="vx-row">
                    <div class="vx-col w-full mb-base">
                        <!-- Col Header -->
                        <div class="flex items-end md:mt-0 mt-base mb-3">
                            <feather-icon
                                icon="GitPullRequestIcon"
                                class="mr-2"
                                svgClasses="w-5 h-5"
                            />
                            <span class="leading-none font-medium"
                                >Access Requests Sent</span
                            >
                        </div>
                        <vs-alert
                            >Below are pending access invitations</vs-alert
                        >

                        <vs-table
                            ref="table"
                            :sst="true"
                            :data="accessRequests"
                            class="mt-6"
                        >
                            <template slot="thead">
                                <vs-th>Email</vs-th>
                                <vs-th></vs-th>
                            </template>

                            <template slot-scope="{ data }">
                                <tbody>
                                    <vs-tr
                                        :data="user"
                                        :key="index"
                                        v-for="(request, index) in data"
                                    >
                                        <vs-td>
                                            {{ request.email }}
                                        </vs-td>

                                        <vs-td>
                                            <div
                                                class="flex justify-end row-hidden-elements"
                                            >
                                                <vx-tooltip
                                                    text="Cancel Access Request"
                                                    title="Remove"
                                                    color="primary"
                                                >
                                                    <vs-button
                                                        type="flat"
                                                        icon-pack="feather"
                                                        icon="icon-delete"
                                                        @click="
                                                            removeAccessRequestConfirm(
                                                                request
                                                            )
                                                        "
                                                    ></vs-button>
                                                </vx-tooltip>
                                            </div>
                                        </vs-td>
                                    </vs-tr>
                                </tbody>
                            </template>
                        </vs-table>
                    </div>
                </div>
            </div>
        </vx-card>
    </div>
</template>

<script>
import { http } from '@/services'
import { textFormat } from '@/mixins/textFormat'

export default {
    name: 'UsersTab',
    mixins: [textFormat],
    created() {
        this.usersFetch()
        this.accessRequestsFetch()
    },
    data() {
        return {
            users: [],
            accessRequests: [],
            selectedUser: null,
            selectedAccessRequest: null,
            form: {
                email: null,
            },
            errors: {
                email: null,
            },
        }
    },
    computed: {
        validateForm() {
            return !!this.form.email
        },
    },
    methods: {
        usersFetch() {
            http.get('user_access')
                .then((response) => {
                    this.users = response.data
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        removeAccessConfirm(user) {
            this.selectedUser = user
            this.$vs.dialog({
                type: 'confirm',
                color: 'secondary',
                title: `Revoke Access`,
                text: `Remove access for ${this.selectedUser.email}`,
                accept: this.removeAccess,
                acceptText: 'Remove',
            })
        },
        removeAccess() {
            http.delete('user_access', { params: { id: this.selectedUser.id } })
                .then((response) => {
                    this.$vs.notify({
                        title: 'Success',
                        text: 'User access revoked',
                        color: 'success',
                        icon: 'feather',
                        iconPack: 'icon-delete',
                        position: 'top-center',
                    })
                    this.usersFetch()
                })
                .catch((error) => {
                    console.log(error)
                    this.$vs.notify({
                        title: 'Error',
                        text: error.response.data,
                        color: 'warning',
                        icon: 'feather',
                        iconPack: 'icon-save',
                        position: 'top-center',
                    })
                })
        },
        invite() {
            this.clearErrors()
            this.$vs.loading({
                color: 'primary',
                container: '#button-with-loading',
                scale: 0.45,
            })
            http.post('user_access', this.form)
                .then((response) => {
                    this.$vs.notify({
                        title: 'Success',
                        text: `Invitation email sent to ${this.form.email}, check inbox and click link to continue`,
                        color: 'success',
                        icon: 'feather',
                        iconPack: 'icon-send',
                        position: 'top-center',
                        time: 6000,
                    })
                    this.accessRequestsFetch()
                    this.form.email = null
                    this.$vs.loading.close(
                        '#button-with-loading > .con-vs-loading'
                    )
                })
                .catch((error) => {
                    this.handleErrors(error.response.data)
                    this.$vs.loading.close(
                        '#button-with-loading > .con-vs-loading'
                    )
                })
        },
        handleErrors(errors) {
            console.log(errors)
            if (errors.email) this.errors.email = errors.email[0]
        },
        clearErrors() {
            this.errors.email = null
        },

        accessRequestsFetch() {
            http.get('access_request_list')
                .then((response) => {
                    this.accessRequests = response.data
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        removeAccessRequestConfirm(request) {
            this.selectedAccessRequest = request
            this.$vs.dialog({
                type: 'confirm',
                color: 'secondary',
                title: `Revoke Access Request`,
                text: `Remove access for ${this.selectedAccessRequest.email}`,
                accept: this.removeAccessRequest,
                acceptText: 'Remove',
            })
        },
        removeAccessRequest() {
            http.delete('access_request_list', {
                params: { id: this.selectedAccessRequest.id },
            })
                .then((response) => {
                    this.$vs.notify({
                        title: 'Success',
                        text: 'Access request revoked',
                        color: 'success',
                        icon: 'feather',
                        iconPack: 'icon-delete',
                        position: 'top-center',
                    })
                    this.accessRequestsFetch()
                })
                .catch((error) => {
                    console.log(error)
                    this.$vs.notify({
                        title: 'Error',
                        text: error.response.data,
                        color: 'warning',
                        icon: 'feather',
                        iconPack: 'icon-save',
                        position: 'top-center',
                    })
                })
        },
    },
}
</script>

<style>
.row-hidden-elements {
    visibility: hidden;
}
.vs-table tr:hover .row-hidden-elements {
    visibility: visible;
}
</style>
