<template>
    <div         id="div-with-loading"
 class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
        <vx-card>
            <div id="user-edit-tab-info">
                <div class="vx-row">
                    <div class="vx-col w-full md:w-1/2 mb-base">
                        <!-- Col Header -->
                        <div class="flex items-end md:mt-0 mt-base">
                            <feather-icon
                                icon="UserIcon"
                                class="mr-2"
                                svgClasses="w-5 h-5"
                            />
                            <span class="leading-none font-medium"
                                >Notifications</span
                            >
                        </div>

                         <div class="flex mt-8">
                            <label for="" class="mr-auto">Client Completed Will</label>
                            <vs-switch
                                color="success"
                                v-model="form.completed_wills"
                                vs-icon="done"
                            >
                            </vs-switch>
                        </div>
                        <small class="text-small mt-3">Receive an email when a client completes their Will</small>

                    </div>
                </div>
                   <div class="vx-row">
                    <div class="flex flex-wrap items-center">
                        <vs-button
                            class="bg-success ml-4 mt-2"
                            icon-pack="feather"
                            icon="icon-save"
                            v-on:click="save"
                            >Save</vs-button
                        >
                    </div>
                </div>
            </div>
        </vx-card>
    </div>
</template>

<script>
import { http } from '@/services'
import { textFormat } from '@/mixins/textFormat'

export default {
    name: 'NotificationsTab',
    mixins: [textFormat],
    mounted() {
                   this.$store.dispatch('brokerFetch')

    },
    watch: {
        '$store.state.broker': function () {
            if (this.$store.getters.broker) {
                this.form.completed_wills = this.$store.getters.broker.completed_wills
            }
        }
    },
    data() {
        return {
            form: {
                completed_wills: null,
            },
        }
    },
    computed: {

    },
    methods: {
          save() {
        
            this.$vs.loading({
                container: '#div-with-loading',
                scale: 0.6,
            })
     

            http.patch('account_settings', this.form)
                .then((response) => {
                    this.$vs.notify({
                        title: 'Success',
                        text: 'Details saved',
                        color: 'success',
                        icon: 'feather',
                        iconPack: 'icon-save',
                        position: 'top-center',
                    })
                    this.$vs.loading.close(
                        '#div-with-loading > .con-vs-loading'
                    )
                    this.$store.commit('broker', response.data)
                    // this.brokerDataFetch()
                })
                .catch((error) => {
                    this.$vs.notify({
                        title: 'Error',
                        text: 'Details not saved',
                        color: 'warning',
                        icon: 'feather',
                        iconPack: 'icon-save',
                        position: 'top-center',
                    })
                    this.$vs.loading.close(
                        '#div-with-loading > .con-vs-loading'
                    )
                })
        },
      
    },
}
</script>

<style>
.row-hidden-elements {
    visibility: hidden;
}
.vs-table tr:hover .row-hidden-elements {
    visibility: visible;
}
</style>
