<template>
    <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
        <vx-card>
            <div id="user-edit-tab-info">
                <div class="vx-row">
                    <div class="vx-col w-full md:w-1/2 mb-base">
                        <!-- Col Header -->
                        <div class="flex items-end md:mt-0 mt-base">
                            <feather-icon
                                icon="LockIcon"
                                class="mr-2"
                                svgClasses="w-5 h-5"
                            />
                            <span class="leading-none font-medium"
                                >Password</span
                            >
                        </div>

                        <div class="mb-3">
                            <vs-input
                                class="w-full mt-4"
                                label="Old Password"
                                v-model="form.old_password"
                                name="old_password"
                                :danger="errors.old_password !== null"
                                :danger-text="errors.old_password"
                            />
                        </div>

                        <div class="mb-3">
                            <vs-input
                                class="w-full"
                                label="New Password"
                                v-model="form.new_password"
                                name="new_password"
                                :danger="errors.new_password !== null"
                                :danger-text="errors.new_password"
                            />
                        </div>
                        <div class="mb-base">
                            <vs-input
                                class="w-full"
                                label="Repeat Password"
                                v-model="form.repeat_password"
                                name="repeat_password"
                                :danger="errors.repeat_password !== null"
                                :danger-text="errors.repeat_password"
                            />
                        </div>
                        <div class="vx-row">
                            <div class="flex flex-wrap items-center">
                                <vs-button
                                    class="bg-success ml-4 mt-2"
                                    icon-pack="feather"
                                    icon="icon-save"
                                    :disabled="!validateForm"
                                    v-on:click="save"
                                    >Change</vs-button
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </vx-card>
    </div>
</template>

<script>
import { http } from '@/services'
import { textFormat } from '@/mixins/textFormat'

export default {
    name: 'PasswordTab',
    mixins: [textFormat],
    created() {},
    data() {
        return {
            users: [],
            accessRequests: [],
            selectedUser: null,
            selectedAccessRequest: null,
            form: {
                old_password: null,
                new_password: null,
                repeat_password: null,
            },
            errors: {
                old_password: null,
                new_password: null,
                repeat_password: null,
            },
        }
    },
    computed: {
        validateForm() {
            return !!(
                this.form.old_password &&
                this.form.new_password &&
                this.form.repeat_password &&
                this.form.new_password === this.form.repeat_password
            )
        },
    },
    methods: {
        handleErrors(errors) {
            if (errors.old_password)
                this.errors.old_password = errors.old_password[0]
            if (errors.new_password)
                this.errors.new_password = errors.new_password[0]
            if (errors.repeat_password)
                this.errors.repeat_password = errors.repeat_password[0]
        },
        clearErrors() {
            this.errors.old_password = null
            this.errors.new_password = null
            this.errors.repeat_password = null
        },
        clearForm() {
            this.form.old_password = null
            this.form.new_password = null
            this.form.repeat_password = null
        },
        save() {
            this.clearErrors()
            http.put('password_change', this.form)
                .then((response) => {
                    this.clearForm()
                    this.$vs.notify({
                        title: 'Success',
                        text: 'Password Changed',
                        color: 'success',
                        icon: 'icon-lock',
                        iconPack: 'feather',
                        position: 'top-center',
                    })
                })
                .catch((error) => {
                    this.handleErrors(error.response.data)
                })
        },
    },
}
</script>

<style>
.row-hidden-elements {
    visibility: hidden;
}
.vs-table tr:hover .row-hidden-elements {
    visibility: visible;
}
</style>
