<template>
    <div
        v-if="form"
        id="div-with-loading"
        class="tab-general md:ml-4 md:mt-0 mt-4 ml-0"
    >
        <vx-card>
            <div id="user-edit-tab-info">
                <div class="vx-row">
                    <div class="vx-col w-full md:w-1/2 mb-base">
                        <!-- Col Header -->
                        <div class="flex items-end md:mt-0 mt-base">
                            <feather-icon
                                icon="HomeIcon"
                                class="mr-2"
                                svgClasses="w-5 h-5"
                            />
                            <span class="leading-none font-medium"
                                >Account</span
                            >
                        </div>

                        <!-- Account Content -->
                        <div class="mb-base">
                            <vs-input
                                class="w-full mt-4"
                                label="Company Name"
                                v-model="form.company_name"
                                name="company_name"
                                :danger="errors.company_name !== null"
                                :danger-text="errors.company_name"
                            />
                        </div>

                        <!-- Col Header -->
                        <div class="flex items-end md:mt-0 mt-base">
                            <feather-icon
                                icon="PhoneIcon"
                                class="mr-2"
                                svgClasses="w-5 h-5"
                            />
                            <span class="leading-none font-medium"
                                >Contact</span
                            >
                        </div>

                        <!-- Contact Content -->
                        <div>
                            <vs-input
                                class="w-full mt-4"
                                label="Contact Name"
                                v-model="form.contact_name"
                                name="contact_name"
                                :danger="errors.contact_name !== null"
                                :danger-text="errors.contact_name"
                            />

                            <vs-input
                                class="w-full mt-4"
                                label="Contact Number"
                                v-model="form.contact_number"
                                name="contact_number"
                                :danger="errors.contact_number !== null"
                                :danger-text="errors.contact_number"
                            />
                            <vs-input
                                class="w-full mt-4"
                                label="Mobile Number"
                                v-model="form.mobile_number"
                                name="mobile_number"
                                :danger="errors.mobile_number !== null"
                                :danger-text="errors.mobile_number"
                            />
                            <vs-input
                                class="w-full mt-4"
                                label="Contact Email"
                                v-model="form.contact_email"
                                name="contact_email"
                                :danger="errors.contact_email !== null"
                                :danger-text="errors.contact_email"
                            />
                        </div>
                    </div>
                    <div class="vx-col w-full md:w-1/2 mb-base">
                        <!-- Col Header -->
                        <div class="flex items-end md:mt-0 mt-base">
                            <feather-icon
                                icon="MapPinIcon"
                                class="mr-2"
                                svgClasses="w-5 h-5"
                            />
                            <span class="leading-none font-medium"
                                >Address</span
                            >
                        </div>

                        <!-- Col Content -->
                        <div>
                            <vs-input
                                class="w-full mt-4"
                                label="Address Line 1"
                                v-model="form.address_line_1"
                                name="address_line_1"
                                :danger="errors.address_line_1 !== null"
                                :danger-text="errors.address_line_1"
                            />
                            <vs-input
                                class="w-full mt-4"
                                label="Address Line 2"
                                v-model="form.address_line_2"
                                :danger="errors.address_line_2 !== null"
                                :danger-text="errors.address_line_2"
                            />
                            <vs-input
                                class="w-full mt-4"
                                label="Post Code"
                                v-model="form.post_code"
                                :danger="errors.post_code !== null"
                                :danger-text="errors.post_code"
                            />
                        </div>

                        <!-- Col Header -->
                        <div class="flex items-end mt-base mb-3">
                            <feather-icon
                                icon="ImageIcon"
                                class="mr-2"
                                svgClasses="w-5 h-5"
                            />
                            <span class="leading-none font-medium">Logo</span>
                        </div>
                        <div class="p-16">
                            <BtnImageSelect
                                :logo="form.logo"
                                @logoChanged="form.logo = $event"
                                :acceptFiles="'image/*'"
                            ></BtnImageSelect>
                        </div>

                        <p class="text-danger" v-if="errors.log !== null">
                            {{ errors.logo }}
                        </p>
                    </div>
                </div>

                <div class="vx-row">
                    <div class="flex flex-wrap items-center">
                        <vs-button
                            class="bg-success ml-4 mt-2"
                            icon-pack="feather"
                            icon="icon-save"
                            v-on:click="save"
                            >Save</vs-button
                        >
                    </div>
                </div>
            </div>
        </vx-card>
    </div>
</template>

<script>
import { http } from '@/services'
import BtnImageSelect from '@/components/common/BtnImageSelect'

export default {
    name: 'GeneralTab',
    components: {
        BtnImageSelect,
    },
    created() {
        this.brokerDataFetch()
    },
    watch: {
        '$store.state.broker': function() {
            this.form = Object.assign({}, this.$store.getters.broker)
        }
    },
    data() {
        return {
            form: {
                logo: null,
                company_name: null,
                contact_name: null,
                mobile_number: null,
                contact_email: null,
                address_line_1: null,
                address_line_2: null,
                post_code: null,
                contact_number: null,
            },
            errors: {
                logo: null,
                company_name: null,
                contact_name: null,
                mobile_number: null,
                contact_email: null,
                address_line_1: null,
                address_line_2: null,
                post_code: null,
                contact_number: null,
            },
        }
    },
    methods: {
        save() {
            this.clearErrors()
            this.$vs.loading({
                container: '#div-with-loading',
                scale: 0.6,
            })
            var formData = new FormData()

            for (const property in this.form) {
                if (this.form[property] !== null)
                    formData.append(property.toString(), this.form[property])
            }

            if (typeof formData.get('logo') === 'string')
                formData.delete('logo')

            http.patch('account_settings', formData)
                .then((response) => {
                    this.$vs.notify({
                        title: 'Success',
                        text: 'Details saved',
                        color: 'success',
                        icon: 'feather',
                        iconPack: 'icon-save',
                        position: 'top-center',
                    })
                    this.$vs.loading.close(
                        '#div-with-loading > .con-vs-loading'
                    )
                    this.$store.commit('broker', response.data)
                    // this.brokerDataFetch()
                })
                .catch((error) => {
                    this.$vs.notify({
                        title: 'Error',
                        text: 'Details not saved',
                        color: 'warning',
                        icon: 'feather',
                        iconPack: 'icon-save',
                        position: 'top-center',
                    })
                    this.$vs.loading.close(
                        '#div-with-loading > .con-vs-loading'
                    )
                    this.handleErrors(error.response.data)
                })
        },
        handleErrors(errors) {
            if (errors.logo) this.errors.logo = errors.logo[0]
            if (errors.company_name)
                this.errors.company_name = errors.company_name[0]
            if (errors.contact_name)
                this.errors.contact_name = errors.contact_name[0]
            if (errors.mobile_number)
                this.errors.mobile_number = errors.mobile_number[0]
            if (errors.contact_email)
                this.errors.contact_email = errors.contact_email[0]
            if (errors.address_line_1)
                this.errors.address_line_1 = errors.address_line_1[0]
            if (errors.address_line_2)
                this.errors.address_line_2 = errors.address_line_2[0]
            if (errors.post_code) this.errors.post_code = errors.post_code[0]
            if (errors.contact_number)
                this.errors.contact_number = errors.contact_number[0]
        },
        clearErrors() {
            this.errors.logo = null
            this.errors.company_name = null
            this.errors.contact_name = null
            this.errors.mobile_number = null
            this.errors.contact_email = null
            this.errors.address_line_1 = null
            this.errors.address_line_2 = null
            this.errors.post_code = null
            this.errors.contact_number = null
        },
        brokerDataFetch(broker_pk) {
            this.$store.dispatch('brokerFetch')
        },
    },
}
</script>
